.lobby {
    text-align: center;
    color: lightgray;
    font-size: 18px;
}

.lobby .title {
    width: 100vw;
}

.lobby .title img {
    max-height: 50vh;
    margin: 0 auto;
    display: block;
}

.lobby #lobby-view {
    padding: 0px !important;
}

.lobby .hidden {
    display: none;
}

.lobby button {
    color: black;
}

.lobby #instances table {
    margin: 0 auto;
    border-spacing: 5px;
}

.lobby #instances td {
    border: 1px solid lightgray;
    padding: 5px;
}

.lobby .phase-title > * > * {
    padding: 10px;
}

.lobby .expansion {
    text-transform: capitalize;
    margin: 0 2px 0 0;
}

.lobby .bgio-client {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
}

.toggle-sound {
    background-size: cover;
    position: fixed;
    bottom: 5px;
    left: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background-color: lightgray;
}

.toggle-sound.off {
    background-image: url('/sound-off.png');
}

.toggle-sound.on {
    background-image: url('/sound-on.png');
}

.leave-button {
    position: fixed;
    bottom: 5px;
    left: 40px;
    background-color: red;
    color: black;
    cursor: pointer;
}
