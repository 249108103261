.set-mode-panel {
    position: fixed;
    left: 20px;
    top: 20px;
}

.set-mode-panel .section {
    margin-bottom: 20px;
}

.set-mode-panel button {
    width: 80px;
    height: 40px;
    font-size: 14px;
    margin-bottom: 10px;
    display: block;
    border-width: 0;
    user-select: none;
}

.set-mode-panel button.selectable {
    cursor: pointer;
    background-color: rgba(128, 128, 128, 1.0);
}

.set-mode-panel button.selectable:active {
    transform: scale(0.99);
}

.set-mode-panel button.selectable:hover {
    background-color: rgba(128, 128, 128, 0.9);
}

.set-mode-panel button.toggled {
    background-color: rgba(0, 128, 0, 1.0);
}

.set-mode-panel button.clickable {
    cursor: pointer;
    background-color: rgba(128, 80, 0, 1.0);
}

.set-mode-panel button.clickable:hover {
    background-color: rgba(128, 80, 0, 0.8);
}

.set-mode-panel .hotkeys-panel {
    position: fixed;
    top: 10vh;
    left: 35vw;
    width: calc(30vw - 40px);
    height: 80vh;
    background-color: white;
    border: 2px solid black;
    margin: 0 auto;
    color: black;
    padding: 20px;
}

.set-mode-panel .hotkeys-panel table {
    border-spacing: 10px;
    margin-bottom: 20px;
}

.set-mode-panel .hotkeys-panel > button {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 30px;
    height: 30px;
    background-color: rgba(255, 0, 0, 1.0);
}

.set-mode-panel .hotkeys-panel > button:hover {
    background-color: rgba(255, 0, 0, 0.6);
}
