.fill {
    width: 100%;
    height: 100%;
}

.card-value {
    position: absolute;
    left: 2px;
    top: 1px;
    font-size: 18px;
    background: radial-gradient(rgba(200, 200, 170, 1), rgba(200, 200, 170, 1), rgba(200, 200, 170, 0));
    width: 20px;
    height: 44px;
    padding: 4px;
    text-align: center;
    border-radius: 3px;
}

.hidden {
    display: none;
}

.red {
    color: rgb(140, 0, 0);
}

.black {
    color: rgb(0, 0, 0);
}
